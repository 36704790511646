import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=83bc8acc&scoped=true&"
import script from "./Notifications.vue?vue&type=script&lang=ts&"
export * from "./Notifications.vue?vue&type=script&lang=ts&"
import style0 from "./Notifications.vue?vue&type=style&index=0&id=83bc8acc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83bc8acc",
  null
  
)

/* custom blocks */
import block0 from "./Notifications.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTimeline from 'quasar/src/components/timeline/QTimeline.js';
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QTab,QInfiniteScroll,QBtn,QTimeline,QTimelineEntry,QSpinnerDots,QIcon,QInnerLoading});
