























































































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import QTimeline from 'quasar/src/components/timeline/QTimeline.js';import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';;
import ability from '../ability';
import axios from 'axios';
import config from '@/config';

@Component({
  components: {
    QTimeline,
    QTimelineEntry,
    QInfiniteScroll,
    QSpinnerDots,
  },
})
export default class Notifications extends Vue {
  public $store: any;
  public loading: boolean = false;
  public notifications: any = [];
  public tab: any = 'unseen';
  public requireHashScroll: any = null;

  public beforeMount() {
    this.loading = true;
    if (this.$route.hash) {
      this.requireHashScroll = this.$route.hash.slice(1);
      this.tab = 'all';
    }

    if (this.$store.state.notificationCountUnseen === 0) {
      this.tab = 'all';
    }

    this.loadNotifications();
  }

  @Watch('notifications')
  public checkForHashScroll() {
    if (this.requireHashScroll !== null) {
      const pattern = `a[name="${this.requireHashScroll}"]`;
      if (document.querySelector(pattern)) {
        const hash = this.requireHashScroll;
        const nid = parseInt(hash.match(/nid-(\d+)/)[1], 10);
        this.readNotificationById(nid);
        this.requireHashScroll = null;
        const obj = document.querySelector(pattern);
        if (obj) {
          obj.scrollIntoView({
            behavior: 'smooth',
          });
        }
      } else {
        this.loadNotificationPrevious(undefined, undefined);
      }
    }
  }

  @Watch('$store.state.notificationLasts')
  public onNotificationLastsChanged(entries: any) {
    const ids = this.notifications.map((entry: any) => entry.id);
    const newNotifications = this.notifications.slice();
    entries.slice().reverse().forEach((entry: any) => {
      if (this.tab === 'unseen' && entry.seen) {
        return;
      }
      if (ids.includes(entry.id)) {
        return;
      }
      newNotifications.unshift(entry);
    });
    this.notifications = newNotifications.sort(
      (entry1: any, entry2: any) => entry2.id - entry1.id);
  }

  @Watch('tab')
  public loadNotifications() {
    this.loading = true;
    this.notifications = [];
    this.loadNotificationPrevious(undefined, undefined);
  }

  public loadNotificationPrevious(index: any, done: any) {
    // get the older id loaded
    const lasts = this.notifications.slice(-1);
    const  params: any = {};
    if (lasts.length > 0) {
      params.before_id = lasts[0].id;
    }
    if (this.tab === 'unseen') {
      params.unseen = true;
    }

    axios.get(`${config.api_url}/notifications`, { params })
    .then((response) => {
      const finished: boolean = this.mergeNotificationsPrevious(response.data.notifications);
      this.loading = false;
      if (done !== undefined) {
        done(finished);
      }
    })
    .catch((error) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  public mergeNotificationsPrevious(entries: any) {
    const ids = this.notifications.map((entry: any) => entry.id);
    const newNotifications = this.notifications.slice();
    let finished = true;
    entries.forEach((entry: any) => {
      if (ids.includes(entry.id)) {
        return;
      }
      newNotifications.push(entry);
      finished = false;
    });
    this.notifications = newNotifications.sort(
      (entry1: any, entry2: any) => entry2.id - entry1.id);
    return finished;
  }

  public readNotification(notification: any) {
    this.readNotificationById(notification.id);
  }

  public readNotificationById(notificationId: any) {
    this.notifications.forEach((entry: any) => {
      if (entry.id === notificationId) {
        if (!entry.seen) {
          entry.seen = true;
          this.$store.commit('notificationRead', notificationId);
        }
      }
    });
    if (this.tab === 'unseen') {
      this.notifications = this.notifications.filter((entry: any) => !entry.seen);
    }
    axios.patch(`${config.api_url}/notification/${notificationId}/read`);
  }

  public readNotifications() {
    this.$store.commit('notificationAllRead');
    this.notifications.forEach((entry: any) => {
      entry.seen = true;
    });
    if (this.tab === 'unseen') {
      this.notifications = [];
    }
    axios.patch(`${config.api_url}/notifications/read`);
  }

  public ackNotifications() {
    this.$store.dispatch('notificationAllAck');
  }

  public deleteAll() {
    this.$q.dialog({
      title: this.$t('notification_delete_all_title') as string,
      message: this.$t('notification_delete_all_message') as string,
      // stackButtons: true,
      cancel: {
        label: this.$t('notification_delete_all_cancel'),
        flat: true,
        color: 'cctext',
      },
      ok: {
        label: this.$t('notification_delete_all_ok'),
        outline: true,
        color: 'cctext',
      },
    })
    .onOk(() => {
      this.$store.commit('notificationDeleteAll');
      this.notifications = [];
      axios.delete(`${config.api_url}/notifications`);
    });
  }
}
